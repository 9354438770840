import React from 'react';

function MultiStepper({ currentStep }) {
  return (
    <div className="step-indicator centered-containerstep">
      <span className={`step ${currentStep === 1 ? 'active' : ''}`}>1</span>
      <span className={`line ${currentStep === 1 ? 'active' : ''}`}><span className='half-line1'></span><span className='half-line2'></span></span>
      <span className={`step ${currentStep === 2 ? 'active' : ''}`}>2</span>
    </div>
  );
}

export default MultiStepper;