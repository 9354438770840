import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import API_URL from './config';
import SemiCircleDonutChart from './SemiCircleDonutChart ';

export default function Score() {
  const location = useLocation();
  const [score, setScore] = useState([]);
  const [Apiresponse, setapiresponse] = useState({})
  const [downloadmsg, setDownloadMsg] = useState("")
  const [tag, setTag] = useState("")
  const [data, setData] = useState([
    { value: 20, color: '#133C55', label: '0%', Description: " GST Score" },
    // { value: 80, color: '#386FA4', label: '100%', Description: "Remaining" },
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const n = params.get('token');
    GetScore(n);
  }, [location.pathname]);

  const GetScore = async (n) => {
    try {
      const response = await axios({
        method: 'Get',
        url: `${API_URL}/Service/GetScore?token=${n}`,
      });

      if (response?.status === 200) {

        const score = response?.data?.score;
        const remainingScore = 100 - score;
        setapiresponse(response?.data)
        setScore(score)
        if (score > 90) {
          setTag('Excellent');
        } else if (score > 75 && score <= 90) {
          setTag('Very Good');
        } else if (score > 60 && score <= 75) {
          setTag('Good');
        } else if (score > 45 && score <= 60) {
          setTag('Average');
        } else {
          setTag('Poor');
        }





        setData([
          { value: score, color: '#133C55', label: `${score}%`, Description: 'GST Score' },
          // { value: remainingScore, color: '#386FA4', label: `${remainingScore}%`, Description: 'Remaining' },
        ]);
      }
      else if (response?.status === 401) {
        alert('Not authorized.')
      } else if (response?.status >= 500) {

      }


    } catch (error) {
      console.log(error)
    }
  }

  const handleDownload = () => {
    if (!Apiresponse.reportPath) {
      setDownloadMsg("Report is not available yet.")
      setTimeout(() => {
        setDownloadMsg("")

      }, 1000)

    } else {
      // If reportPath is not empty, proceed with downloading the file
      let url = `https://api.complianceguru.co.in/${Apiresponse.reportPath}`;
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', url);

      if ('download' in linkElement) {
        linkElement.setAttribute('download', url);
        linkElement.setAttribute('target', '_blank');
        linkElement.click();
      } else {
        window.open(url, '_blank');
      }
    }
  };
  const LegendItem = ({ color, label, Description }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <div style={{ width: '20px', height: '20px', backgroundColor: color, marginRight: '5px' }}></div>
      <span>{label} {Description}</span>
    </div>
  );

  return (
    <div className='centeredscore-container'>
      <div className='container'>
        <div className='row gstscore-row'>
          <div className='col-md-6'>
            <h2 className='score'><strong>{Apiresponse?.name}</strong>, your score is <strong>{tag}</strong> for GST No: <br /> <strong>{Apiresponse?.gsTinNumber}</strong></h2>
            <SemiCircleDonutChart score={score} />
            <div className="legend">
              {data?.map((entry, index) => (
                <LegendItem key={index} color={entry?.color} label={entry?.label} Description={entry?.Description} />
              ))}
            </div>        
          </div>

          <div className='col-md-6 tba-info-h'>
            <div className="tbh-info">
              <div><span>Above 90%</span><img src="./img/right-arrow.png" width="24" alt="" /><span className="highlight">Excellent</span></div>
              <div><span>Between 75% - 90%</span><img src="./img/right-arrow.png" width="24" alt="" /><span className="highlight">Very Good</span></div>
              <div><span>Between 60% - 75%</span><img src="./img/right-arrow.png" width="24" alt="" /><span className="highlight">Good</span></div>
              <div><span>Between 45% - 60%</span><img src="./img/right-arrow.png" width="24" alt="" /><span className="highlight">Average</span></div>
              <div><span>Below 45%</span><img src="./img/right-arrow.png" width="24" alt="" /><span className="highlight">Poor</span></div>

              <div className='report d-flex justify-content-center text-center p-0 mt-30'>
                <button className='reportbtn' onClick={handleDownload}>
                  Download Report
                </button>
                {downloadmsg && <p className="error-msg">{downloadmsg}</p>}              
              </div>
            </div>
          </div>
        </div>

        <div className='gstscore-info-row'>
          <div className='consult text-center'>
            <p> To improve your score, consult with our expert team.</p>
          </div>
        </div>

      </div>
    </div>
  )
}
