import React from 'react'

export default function Header() {
  return (
    <div className='header'>
  

  <h2><img src="../../img/gst-score.png"></img></h2>
   
  
    </div>
  )
}
