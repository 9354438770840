import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from './config';
const Home = ({ setCurrentStep ,handleFormSubmit }) => {
  const navigate = useNavigate();

  const [gst, setGst] = useState(localStorage.getItem('gst') || "");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const[ GstExist ,setGstExist] =useState(false);
  const[ GstResponse ,setGstResponse] =useState([]);

  const handleChange = (e) => {
    setGst(e.target.value.toUpperCase());
    setErrorMsg("");
  }

  const  GetGstCheck =async(data)=>{
   
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Service/CheckGstNumber?gstNumber=${data}`,
           
        }); 
        if(response?.status===200 && response?.data?.exists ==true) {
          setGstResponse(response.data.gstData);
          setGstExist(true)      
          if (response?.data?.exists) {
            setShowModal(true); 
          } else {
            localStorage.setItem('gst', gst);
            setCurrentStep(2);
            navigate('/form', { state: { gst: gst } });
          }
        }
      else{
        localStorage.setItem('gst', gst);
            setCurrentStep(2);
            navigate('/form', { state: { gst: gst } });
      }
       
    } catch (error) {
        console.log(error)
       
    }
  }

  const handleNavigate = (e) => {
    debugger
    if (gst.trim() === "") {
      setErrorMsg("Please fill in the GST number");
    } else {
      let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
      if (!regex.test(gst)) {
        setErrorMsg("Please enter a valid GST number.");
      } else {
        // Check if GST number already exists
        GetGstCheck(gst)
       
      }
    }
  }
  const handleContinueWithExisting = () => {
    localStorage.setItem('gst', gst);
    navigate('/form', { state: { gst: gst , checkLast :true , apiresponse :GstResponse} });
    setShowModal(false);
  };

  const handleContinueWithNew = () => {
    localStorage.setItem('gst', gst);
    navigate('/form', { state: { gst: gst  ,  checkLast :false ,apiresponse :GstResponse} });
    setShowModal(false);
  
  };
 
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem('gst');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="centered-container">
      <h2 className='title'>Get your GST score.</h2>
      <div className="gstform">
        <label>Please enter GST Number:</label>
        <div>
          <input
            type="text"
            id="gstNo"
            name="gstNo"
            value={gst}
            onChange={handleChange}
            required
          />
        </div>
        {errorMsg && <p className="error-msg">{errorMsg}</p>}
        <div className='nextparent'>
          <div className='next continue-btn-h' onClick={handleNavigate} disabled={gst.trim() === ""}>
            Continue
          </div>
        </div>
      </div>
  
      {showModal && <div className="blur-background"></div>}
  
      {/* Modal */}
      {showModal && (
        <>
          <div className="modal-overlay"></div>
          <div className="modalx">
            <div className="modal-content">
              <p>This GST number already exists.</p>
              <button onClick={handleContinueWithExisting}>Continue with existing Score</button>
              <button onClick={handleContinueWithNew}>Continue with new Score</button>
            </div>
          </div>
        </>
      )}
  
      <div className='powered-by'>
        <div className='tba-text'>Powered By</div>
        <div className='tba-img'><img src='../../img/logo-credence.png'></img></div>
      </div>
    </div>
  );
}


export default Home;