import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Form from './Form';
import Score from './Score';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Response from './Response';
import MultiStepper from './MultiStepper';

function App() {
  
  const [currentStep, setCurrentStep] = useState(1);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  // Function to handle form submission
  const handleFormSubmit = () => {
    setFormSubmitted(true);
  };  
  
  // Function to update the current step
  const updateStep = (step) => {
    setCurrentStep(step);
  };

  // Function to get the current route
  const getCurrentRoute = () => {
    const currentPath = window.location.pathname;
    return currentPath;
  };

 // Listen for changes in the browser history
 useEffect(() => {
  // Event listener for popstate event
  const handlePopState = () => {
    setCurrentStep(1); // Reset the current step when user navigates
  };

  window.addEventListener('popstate', handlePopState);

  // Cleanup
  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}, []);

  const currentRoute = getCurrentRoute();

  return (
    <Router>
      <div className="App">
        <Header />
        {(currentRoute === '/form' || currentRoute === '/home') && !formSubmitted && <MultiStepper currentStep={currentStep} />}
        <Routes>
          <Route path="/form" element={<Form handleFormSubmit={handleFormSubmit} setCurrentStep={updateStep} />} />
          <Route path="/home" element={<Home  handleFormSubmit={handleFormSubmit}  setCurrentStep={updateStep} />} />
          <Route path="/success" element={<Response />} />
          <Route path="/score" element={<Score />} />
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}



export default App;