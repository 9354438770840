import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from './config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Form = ({ setCurrentStep, handleFormSubmit }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state ? location.state.gst : undefined;
  const Data = location.state ? location.state.checkLast : undefined;
  const [response, setResponse] = useState(location.state ? location.state.apiresponse : '');
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [nameErrorMsg, setnameErrorMsg] = useState('');

  const AddScore = async (data) => {
    
    try {
      const response = await axios({
        method: 'Post',
        url: `${API_URL}/Service/AddGstScore`,
        data: data
      });

      if (response.status === 200) {
        navigate('/success', { state: { toastMessage: response.data.message } });
      }
      else {
        toast.error(response.data.message);
      }
      
    } catch (error) {
      console.log(error)
      toast.error('An error occurred. Please try again later.');
    }
  }


  const [formData, setFormData] = useState({
    name: '' || response?.name,
    gsTinNumber: stateData || response?.gsTinNumber,
    email: '' || response?.email,
    mobilePhone: '' || response?.mobilePhone,
    companyName: '' || response?.companyName,
    checkLast: Data
  });

  useEffect(() => {
    if (location.state && location.state.gst) {
      setTimeout(() => {
        setFormData(prevState => ({
          ...prevState,
          gsTinNumber: location.state.gst
        }));
      }, 0);
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    let newErrorMsg = '';

    if (name === 'mobilePhone') {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(value)) {
        newErrorMsg = 'Please enter a valid 10-digit phone number';
        setPhoneErrorMsg(newErrorMsg);
      } else {
        setPhoneErrorMsg('');
      }
    } else if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        newErrorMsg = 'Please enter a valid email address';
        setEmailErrorMsg(newErrorMsg);
      } else {
        setEmailErrorMsg('');
      }
    }
    else if (name === 'name') {
      if (value.trim() === '') {
        newErrorMsg = 'Name cannot be empty';
        setnameErrorMsg(newErrorMsg);
      } else {
        setnameErrorMsg('');
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlecompany = (e) => {
    debugger
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!phoneErrorMsg && !emailErrorMsg && !nameErrorMsg) {
      AddScore(formData);
      handleFormSubmit();
      setFormData({
        name: '',
        gsTinNumber: '',
        email: '',
        mobilePhone: '',
        companyName: ''
      });
      localStorage.removeItem('gst');
    }
  };

  const handleNavigate = () => {
    setCurrentStep(1);
    navigate('/home', { state: { gst: formData.gsTinNumber } });
  }
  return (
    <div className="centered-container">
      <h2 className='title'>Get your GST score.</h2>
      <form className="gstform" onSubmit={handleSubmit}>
        <div>
          <label className='hide-desktop'>Please enter your information</label>
          <div>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Name"
            />
          </div>
          {nameErrorMsg && <p className="error-msg">{nameErrorMsg}</p>}
        </div>
        <div>
          <div>
            <input
              type="text" 
              id="mobilePhone" 
              name="mobilePhone" 
              value={formData.mobilePhone}
              onChange={handleInputChange}
              required
              placeholder="Mobile"
              pattern="[0-9]*"
              title="Please enter only numeric characters"

            />
          </div>
          {phoneErrorMsg && <p className="error-msg">{phoneErrorMsg}</p>}
          <div>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              placeholder="Email"
            />
          </div>
          {emailErrorMsg && <p className="error-msg">{emailErrorMsg}</p>}

        </div>
        <div>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handlecompany}

            placeholder="Company Name (optional)"
          />
        </div>

        <div className='tba-buttons'>
          <div className='prev' onClick={handleNavigate} >
            Previous
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
      <div className='powered-by'>
        <div className='tba-text'>Powered By</div>
        <div className='tba-img'><img src='../../img/logo-credence.png'></img></div>
      </div>
    </div>
  );
};

export default Form;