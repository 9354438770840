import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { BiFontSize } from 'react-icons/bi';

const SemiCircleDonutChart = ({ score }) => {
    const options = {
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
            height: 320 // Adjusted width to make it smaller
          }
        }
      }],
        chart: {
          type: 'donut',
          height: '200',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 25,            
            donut: {
              size: '60%',
              labels: {
                show: true
              }
            }
          },
        },
        dataLabels: {
          enabled: true,
        },
        colors: ['#133C55', '#386FA4'], // Yellow for score, Grey for remaining
        
        labels: [' GST Score', 'Remaining'],
        legend: {
          show: false,
        },      
        annotations: {
            points: [{
              x: '50%',
              y: '50%',
              marker: {
                size: 0,
              },
              label: {
                borderColor: 'transparent',
                style: {
                    fontSize: '150px', // Adjust the font size here
                  },
                text: `${score}%`,
              },
          }],
        },
      };
      const series = [score, 100 - score];
    
  
    return (
        <div className='apexchart-container'>
      <div className='apexchart'>
        <ReactApexChart options={options} series={series} type="donut" width={400} height={400} />
      </div>
      </div>
    );
  };
  
  export default SemiCircleDonutChart;