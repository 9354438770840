import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { VscVerifiedFilled } from "react-icons/vsc";

function Response() {
  const location = useLocation();
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState(location.state ? location.state.toastMessage : '');
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    if (toastMessage == '' || toastMessage == undefined) {
      navigate('/home');
    }
  }, [])

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);
    if (timer === 0) {
      
      setToastMessage("")
      navigate('/home')
    }
    return () => clearInterval(countdown);
  }, [navigate, timer]);

  return (
    <div className="success-h">
      <div>
        <VscVerifiedFilled size={50} style={{ color: 'green' }} />
      </div>
      <toast className="success">{toastMessage}</toast>
      <div className='powered-by'>
      <div className="timer">Redirecting in {timer} seconds...</div>
        <div className='tba-text'>Powered By</div>    
        <div className='tba-img'><img src='../../img/logo-credence.png'></img></div>
      </div>
    </div>
  )
}

export default Response